.clientReviewSection {
    text-align: center;
    padding: 10% 0;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-color: transparent;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.8) 100%);
        opacity: 1;
        transition: background .3s, border-radius .3s, opacity .3s;
    }

    .quoteIcon {
        svg {
            width: 75px;
            height: 75px;
            color: #fff;
        }
    }
}
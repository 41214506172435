.iconWeDo {
    width: 135px;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #52b9e9;
    border-radius: 50%;
    margin-bottom: 30px;
    svg {
        width: 60%;
        height: 60%;
        fill: #52b9e9;
    }
}

.canWeHelp {
    position: relative;
    z-index: 0;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-color: transparent;
        background-image: linear-gradient(90deg,#000000 23%, #52b9e9 23%);
        opacity: 1;
        transition: background .3s, border-radius .3s, opacity .3s;
    }
}
.partnerSlider {
    .slick-slide {
        opacity: .6;
        transition: .5s;

        &.slick-center {
            opacity: 1;

            div {
                border: 1px solid #52b9e9;
            }
        }

        div {
            margin: 0 5px;
            padding: 15px;
            background-color: #fff;
            border: 1px solid #ccc;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 100px;
                width: auto !important;
                max-width: 100%;
                object-fit: contain;
            }
        }
    }
}

.slick-slider {
    .slick-dots {
        bottom: 25px;
    }
}
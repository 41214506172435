.MuiAppBar-root.MuiAppBar-colorDefault {
    background-color: #fff;
}

.navMenu {
    @media (min-width: 900px) {
        display: flex;
    }

    li {
        position: relative;
        width: auto;
        padding: 0;

        @media (max-width: 899.99px) {
            display: block;
        }

        a {
            font-size: .875rem;
            font-weight: 500;

            @media (max-width: 899.99px) {
                font-size: 0.75rem;
                border-bottom: 1px solid #eee;
            }

            svg {
                font-size: 1rem;
            }

            &.active {
                color: #52b9e9;
            }
        }

        &:hover {
            .subNavMenu {
                visibility: visible;
                opacity: 1;
                height: auto;
            }
        }

        .subNavMenu {
            background: #fff;
            transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            visibility: hidden;
            opacity: 0;
            height: 0;
            transition: .5s;
            margin: 0;
            padding: 0;

            @media (min-width: 900px) {
                position: absolute;
                top: 100%;
                border-radius: 4px;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
            }

            @media (max-width: 899.99px) {
                li a {
                    padding-left: 30px;
                }
            }

            li {
                a {
                    white-space: nowrap;

                    &.active {
                        color: #00525a;
                        background-color: #52b9e9;
                    }
                }
            }
        }
    }
}
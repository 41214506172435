.bannerSection {
    background-position: top center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    background-attachment: fixed;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background-color: transparent;
        background-image: linear-gradient(180deg, #000000 0%, #0000004D 40%);
        opacity: .8;
        transition: background .3s, border-radius .3s, opacity .3s;
    }

    &.homeBanner {
        min-height: 100dvh;
    }

    &.subBanner {
        flex-direction: column;
        min-height: 75dvh;
        background-position: center;
        &::before {
            background: rgba(0,0,0,.75);
        }
    }
}

.bannerContent {
    padding-right: 40%;
}
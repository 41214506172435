.footerSection {
    background-color: #000;
    color: #808080;

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        justify-content: center;

        li {
            padding: 0;
            width: auto;
            a {
                color: #808080;
                transition: .5s;

                div {
                    min-width: auto;
                    white-space: nowrap;

                    svg {
                        color: #808080;
                        font-size: 2rem;
                        transition: .5s;
                    }
                }

                &:hover {
                    color: #fff;

                    svg {
                        color: #fff;
                    }

                }
            }
        }
    }
}
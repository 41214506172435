
.helpYouContainer {
    
    @media (min-width: 900px) {
        display: flex
    }
    .helpYouImage {
        @media (min-width: 900px) {
            width: 380px;
            margin-top: -108px;
        }
        @media (max-width: 899.99px) {
            max-width: 320px;
            margin-top: -91px;
        }
    }
}
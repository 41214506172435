.listItems {
    padding: 0;
    @media (max-width: 599.99px) {
        padding-left: 24px!important;
    }

    li {
        position: relative;
        padding: 0 0 24px;
        min-height: 100px;
        align-items: flex-start;
        margin-left: auto;
        padding-left: 50px;

        &:not(:last-child) {
            border-left: 1px solid #ccc;
        }

        span.MuiTypography-body2 {
            left: -75px;
        }

        @media (min-width: 600px) {
            width: 50%;
        }

        div {
            margin: 0;
            background: #fff;
            padding: 16px;
            box-shadow: 0 0 14px -10px #000;
            border-radius: 12px;
            position: relative;
            z-index: 0;

            &::before {
                content: "";
                position: absolute;
                left: -13px;
                background: #fff;
                width: 25px;
                height: 25px;
                transform: rotate(45deg);
                z-index: -1;
                box-shadow: -1px 1px 2px -2px #000;
            }
        }

        @media (min-width: 600px) {
            &:nth-child(odd) {
                text-align: right;
                padding-left: 0;
                border-right: 1px solid #ccc;
                border-left: 0;
                padding-right: 50px;
                margin-left: 0;

                div {
                    &::before {
                        right: -13px;
                        left: auto;
                        box-shadow: 1px -1px 2px -2px #000;
                    }
                }

                span.MuiTypography-body2 {
                    right: -75px;
                    left: auto;
                }
            }
        }


        .MuiTypography-body1 {
            margin-bottom: 10px;
            padding-bottom: 5px;
            font-weight: 600;
            color: #52b9e9;
            border-bottom: 1px solid #e5e5e5;
        }

        span.MuiTypography-body2 {
            position: absolute;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f4f4f4;
            border: 1px solid #ccc;
            border-radius: 50%;
            top: 0;
            background-color: #52b9e9;
            color: #fff;
        }
    }
}


.infographyListItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;

    li {
        width: 210px;
        flex-basis: 210px;
        flex-grow: 0;
        max-width: 210px;
        padding: 5px;

        div {
            margin: 0;

            span.MuiTypography-body1 {
                background-color: #52b9e9;
                color: #fff;
                padding: 16px;
                position: relative;
                text-align: center;
                // font-size: 0.875rem;
                box-shadow: 0 0 14px -4px rgba(0, 0, 0, 0.5);
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                min-height: 80px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 0;
                    border: 100px solid transparent;
                    border-top-color: #52b9e9;
                    z-index: 1;
                }

                span {
                    position: absolute;
                    left: 50%;
                    top: 65%;
                    transform: translate(-50%, 65%);
                    z-index: 1;
                    background: #f4f4f4;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-weight: bold;
                    box-shadow: inset 0 0 9px -3px #000;
                }
            }

            p.MuiTypography-body2 {
                padding: 16px;
                background-color: #f4f4f4;
                margin-top: 100px;
                position: relative;
                font-size: 0.825rem;
                color: #757575;
                line-height: 1.5;
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
                box-shadow: 0 0 24px -10px rgba(0, 0, 0, .5);
                min-height: 230px;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                    border: 100px solid transparent;
                    border-bottom-color: #f4f4f4;
                }
            }
        }
    }
}


.infographyListItems1 {
    [class*='list-'] {
        display: flex;
        align-items: center;
        border: 5px solid #f14036;
        margin-bottom: 32px;
        border-radius: 100px;

        @media (max-width: 599.99px) {
            flex-direction: column;
        }

        @media (min-width: 600px) {
            &:nth-child(odd) {
                flex-direction: row-reverse;
                // .infographyContent {
                //     &::after {
                //         left: 0;
                //         right: auto;
                //     }
                // }
            }
        }

        .steps {
            color: #959595;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 1;

            span {
                font-size: 36px;
            }
        }

        .steps,
        .icon {
            max-width: 100px;
            width: 100px;
            flex-basis: 100px;
            flex-grow: 0;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 15px;

            @media (min-width: 600px) {
                margin: 0 15px;
            }
        }

        .icon {
            // border: 5px solid #f14036;
            border-radius: 50%;

            svg {
                font-size: 48px;
                color: #fff;
            }
        }

        .infographyContent {
            text-align: center;
            position: relative;
            padding: 0 16px;

            @media (min-width: 600px) {
                padding: 16px;
                width: calc(100% - 260px);

                &:before,
                &::after {
                    content: "";
                    width: 50%;
                    position: absolute;
                    background-color: #f9f9f9;
                    border-top: 6px dotted #f14036;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &::before {
                    top: -5px;
                }

                &::after {
                    // right: -100px;
                    // width: calc(25% + 100px);
                    // height: 5px;
                    bottom: -5px;
                }
            }



            h6 {
                font-size: 0.75rem;
                color: #fff;
                background-color: #f14036;
                display: inline-block;
                padding: 8px;
                border-radius: 5px;
                line-height: 1;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            div {
                font-size: .825rem;
            }
        }
    }

    .list-0 {
        border: 5px solid #f14036;

        .icon {
            background-color: #f14036;
        }

        .infographyContent {

            &:before,
            &::after {
                border-top: 6px dotted #f14036;
            }

            h6 {
                background-color: #f14036;
            }
        }
    }

    .list-1 {
        border: 5px solid #ffb753;

        .icon {
            background-color: #ffb753;
        }

        .infographyContent {

            &:before,
            &::after {
                border-top: 6px dotted #ffb753;
            }

            h6 {
                background-color: #ffb753;
            }
        }
    }

    .list-2 {
        border: 5px solid #8dc945;

        .icon {
            background-color: #8dc945;
        }

        .infographyContent {

            &:before,
            &::after {
                border-top: 6px dotted #8dc945;
            }

            h6 {
                background-color: #8dc945;
            }
        }
    }

    .list-3 {
        border: 5px solid #06a49a;

        .icon {
            background-color: #06a49a;
        }

        .infographyContent {

            &:before,
            &::after {
                border-top: 6px dotted #06a49a;
            }

            h6 {
                background-color: #06a49a;
            }
        }
    }

    .list-4 {
        border: 5px solid #1a75b3;

        .icon {
            background-color: #1a75b3;
        }

        .infographyContent {

            &:before,
            &::after {
                border-top: 6px dotted #1a75b3;
            }

            h6 {
                background-color: #1a75b3;
            }
        }
    }
}

.infographics2 {
    // width: 320px;
    // flex-basis: 320px;
    // flex-grow: 0;
    // max-width: 320px;
    display: flex;
    position: relative;

    [class*="steps-"] {
        position: absolute;
        top: 15px;
        height: 100%;
        background-color: #ffb753;
        border-radius: 24px;
        padding: 24px;
        display: flex;
        align-items: center;


        span {
            display: flex;
            flex-direction: column;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 1;
            color: #fff;
            font-size: 12px;
            position: relative;

            span {
                font-size: 24px;
                font-weight: 600;

                &::before {
                    content: "";
                    position: absolute;
                    left: 40px;
                    top: 25px;
                    z-index: 2;
                    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.4);
                    transform-origin: 0 0;
                    transform: rotate(-134deg);
                    border: 16px solid black;
                    border-color: transparent transparent #ffb753 #ffb753;
                }
            }
        }
    }

    [class*="steps-"],
    .contentIcons {
        width: calc(100% - 75px);
    }

    .contentIcons {
        display: flex;
        align-items: center;
        padding: 24px;
        border-radius: 24px;
        margin-left: auto;
        background: #f4f4f4;
        z-index: 1;

        h6 {
            font-size: 0.875rem;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 5px;
        }

        p.MuiTypography-body1 {
            font-size: .75rem;
            line-height: 1.25;
        }

        svg {
            font-size: 32px;
            color: #757575;
        }
    }

    .steps-1 {
        background-color: #f89c0a;

        span span::before {
            border-color: transparent transparent #f89c0a #f89c0a;
        }
    }

    .steps-2 {
        background-color: #6d14ae;

        span span::before {
            border-color: transparent transparent #6d14ae #6d14ae;
        }
    }

    .steps-3 {
        background-color: #ff5800;

        span span::before {
            border-color: transparent transparent #ff5800 #ff5800;
        }
    }

    .steps-4 {
        background-color: #4c63ff;

        span span::before {
            border-color: transparent transparent #4c63ff #4c63ff;
        }
    }

    .steps-5 {
        background-color: #f91c51;

        span span::before {
            border-color: transparent transparent #f91c51 #f91c51;
        }
    }

    .steps-6 {
        background-color: #00b3c2;

        span span::before {
            border-color: transparent transparent #00b3c2 #00b3c2;
        }
    }

}

.sections:nth-child(even) {
    .infographics2 .contentIcons {
        background-color: #fff;
    }
}
.imageOverlay {
    position: relative;
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background-color: transparent;
        background-image: linear-gradient(180deg, #000000 0%, #0000003B 100%);
        opacity: .5;
    }
}

p.MuiTypography-body1 {
    font-size: .875rem;
    color: #757575;
    line-height: 2;
}
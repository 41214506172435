.mainContent {
  overflow: hidden;
}
html, body, #root, .mainContent {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

.sections:nth-child(even) {
  background: #f9f9f9;
}

.sections-grey{
  background: #f4f4f4;
}

.statisticSections{
  background-image: url(./assets/images/stat_background.jpg);
  background-size: cover;
  opacity: 75%;
}

.support{
  background-image: url(./assets/images/support.jpg);
  background-size: 100%
}

